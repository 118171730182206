.dropzone-container {
    position: relative;
    display: flex;
    min-height: 100px;
    border: 2px dashed #bbb;
    align-items: center;
    padding: 20px 10px;
}

.dropzone-input {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.dropzone-preview {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.dropzone-preview-image {
    flex-basis: 0;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    margin-right: 10px;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.dropzone-preview-filename {
    word-wrap: anywhere;
}

.dropzone-preview-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

.dropzone-preview-button::before {
    content: '×';
    padding: 3px 7px;
    cursor: pointer;
}

.dropzone-placeholder {
    flex-grow: 1;
    text-align: center;
    color: #999;
}
