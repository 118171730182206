// label.required::after {
//     content: '*';
//     margin-left: 0.25rem;
//     color: #d63939;
// }

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.5;
}
// grey out placeholder for inputs
.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #676a6c;
    opacity: 0.5;
}
.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #676a6c;
}
.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #676a6c;
}

// form collection
.collection-body {
    list-style-type: none;
    ul {
        padding-left: 0;
    }
}
.collection-content {
    margin-bottom: 10px;

    &.invalid-header > .card {
        background-color: #ffe1e1;
    }

    .card-header .btn-link:hover,
    .card-header .btn-link:focus {
        color: black;
        font-weight: 500;
    }

    .placeholder-delete {
        &.accordion {
            cursor: pointer;
            color: #C74E4E;
            padding: 3px 16px 10px;
            font-size: 1.2rem;
        }
    }
}
ul[data-prototype] {
    list-style: none;
}


.custom-file-label {
    border: 1px solid #e5e6e7;
    border-radius: 0;
    height: 35px;
    &::after {
        border-radius: inherit;
        content: 'Parcourir';
        height: 33px;
    }
}
.input-group-text {
    border-color: #e5e6e7;
    padding: 0.3rem 0.75rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
    color: #ced4d9;
}

.datatable-checkbox:disabled {
    color: #ced4d9;
}
[data-change-sign] {
    margin-right: 20px;
}
.switch-field {
    display: flex;
    margin-bottom: 36px;
    //overflow: hidden;
    margin-top: 14px;
}

.switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.switch-field label {
    background-color: white;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
    cursor: pointer;
}

.switch-field input:checked + label {
    background-color: $blue;
    box-shadow: none;
    color: white;
    font-weight: bold;
}

.switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
    border-radius: 0 4px 4px 0;
}
#service-filters {
    background: $gray;
    padding-top: 8px;

    &.switch-field {
        margin: 0;
    }
}
a.service-toggle-back {
    margin-right: 20px;
}

// tom-select overrides

.ts-control::placeholder {
    color: red;
}
.ts-control {
    border: 1px solid #ecedee;
}
.ts-input {
    border: 1px solid #ebeced;
    border-radius: initial;
}
.ts-dropdown,
.ts-dropdown.form-control {
    border-radius: initial;
}
.ts-dropdown-content,
.ts-control > div {
    .address,
    .organization {
        display: block;
        font-size: 0.8em;
        color: $dark-gray;
    }
}

// CKEditor overrides

.ck-editor__editable_inline {
    min-height: 125px !important;
}
