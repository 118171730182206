// Default theme
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,500,700');

// Logos
$logo-path: url('../img/app-logo.png');
$logo-minified-path: url('../img/app-logo-minified.png');

// Basic Colors
$navy: #71A55F;       // Primary color
$dark-gray: #c2c2c2;  // Default color
$blue: #337AB7;       // Success color
$lazur: #AFCBC7;      // Info color
$yellow: orange;     // Warning color
$red: #C74E4E;        // Danger color

// Various colors
$text-color: #676a6c;   // Body text
$gray: #f3f3f4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badge
$label-badge-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spinner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

//Basics
$basic-link-color: $blue;

// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: url('../img/patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-text-color: #a7b1c2;
$nav-text-color-active: #a7b1c2;

$cta-color: #222654;
$cta-secondary-color: #aaacc7;
$cta-success-color: #23955c;
$cta-warning-color: orange;
$cta-height: 33px;
$cta-min-width: 120px;
$cta-border-radius: 3px;
$nav-grey: #212529; // Dark side nav color
$text-disabled: #ccc;
$light-green: #bdc667;
$selected-row-bg: #ddd;

$notie-color-success: $cta-secondary-color;
$notie-color-warning: #d6a14d;
$notie-color-error: $cta-warning-color;
$notie-color-info: $cta-color;
$notie-color-neutral: darken(#a0a0a0, 4%);

$cta-icon-color: #fff;

@import './app';
