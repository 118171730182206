@import './form';
@import './choices';
@import './components';

body,
html,
.header {
    max-width: 100vw;
}

#wrapper {
    overflow-x: unset !important;
}

body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    display: flex;
}
body.mini-navbar .navbar-default .nav.nav-second-level li a span {
    line-height: 15px;
    display: block;
}

.brand-logo {
    content: $logo-path;

    &.minified {
        content: $logo-minified-path;
    }
}

//
.navbar-static-top {
    height: 66px;

    .navbar-top-links {
        display: block;
    }
}

.navbar-minimalize {
    background: $nav-bg;
    color: $gray;
}

// generic modals behaviour
.modal-header {
    a.close {
        font-size: 1rem;
        font-weight: 600;
        color: grey;
        padding: 1rem;
    }
}
.modal-body {
    // do not display scrollbar
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox
    scrollbar-color: transparent transparent;

    // but allow to scroll if needed
    overflow-y: scroll;
    max-height: 700px;

    .nav-link {
        padding: 0.5rem 0.3rem;
    }
}

.modal-content {
    min-height: 500px;
}

// informative modal style
#modal-info {
    .modal-body {
        min-height: initial;
    }
}

.dt-bootstrap4 {
    // avoid user to select text (drag click or double click)
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // catch only target link when clicking on it, not its children (b, span, ...)
    // hence in JavaScript event, e.target.localName always return 'a'
    a > * {
        pointer-events: none;
    }
}

.page-heading {
    padding: unset !important;

    h2 {
        font-weight: 700;
        font-size: 20px;
    }

    .counter {
        font-size: 0.8rem;
        background: $nav-bg;
        color: #fff;
        padding: 2px 6px;
        border-radius: 3px;
        margin: 20px 5px;
        font-weight: 600;
    }

    .static-primary-actions {
        button {
            margin-left: 12px;
        }
    }

    .breadcrumb-container {
        background-color: #fefeff;
        border-top: 1px solid #f3f3f3;

        .breadcrumb {
            margin: 0.3rem;
            background-color: #fefeff;

            .breadcrumb-item {
                line-height: 1rem;
                a {
                    font-weight: 500;
                }
            }
        }
    }
}

.fa-comment,
.fa-comment-slash {
    font-size: 1.2em;
}
.fa-comment-slash {
    opacity: 0.1;
}
.fa-rentalcar::before {
    content: '\f1b9';
}

.fa-restaurant::before {
    content: '\f2e7';
}

.fa-hotel::before {
    content: '\f236';
}

.training-rights {
    &__item-option {
        background: #ffffff;
        border: 1px solid #20b394;
        border-radius: 8px;
        padding: 1rem;

        i {
            font-size: 5rem;
        }

        label {
            margin: 6px 0px 0px 6px;
        }

        .form-check-input[disabled] ~ .form-check-label,
        .form-check-input:disabled ~ .form-check-label {
            color: #d0d8df;
        }
    }
}

.green-dot,
.red-dot {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
}

.green-dot {
    background-color: $cta-success-color;
}

.red-dot {
    background-color: #f48793;
}

.user-progress-bar {
    background: #f3f3f3;
    border-radius: 50px;
    height: 16px;
    overflow: hidden;
}

.user-progress-bar-stat {
    height: 16px;
}

.service-cancel {
    background: #fc5b57;
}

.bg-state-1 {
    background: #ff8300;
}
.bg-state-2 {
    background: #ffa800;
    &.delivery {
        background: #d3e100;
    }
}
.bg-state-3 {
    background: #ffe500;
}
.bg-state-4 {
    background: #d3e100;
}
.bg-state-5 {
    background: #93e500;
}
.bg-state-6 {
    background: #0de000;
}

.delivery-list-icon {
    display: block;
    font-size: 1.2rem;

    &.processing {
        color: #ffe500;
    }
    &.awaiting_invoice {
        color: $yellow;
    }
    &.completed {
        color: #d3e100;
    }
    &.cancelled {
        color: #fc5b57;
    }
    &.invoiced {
        color: #d3e100;
    }
}

.service-list-icon {
    display: block;
    margin: 0 3px;
    font-size: 1.2rem;
    color: #4a6b8b;


    &.being_set,
    &.service_requested_1 {
        color: #ff8300;
    }
    &.awaiting_processing,
    &.service_requested_2 {
        color: $yellow;
    }
    &.processing,
    &.service_requested_3 {
        color: #ffe500;
    }
    &.cancellation_requested {
        color: $yellow;
    }
    &.awaiting_invoice {
        color: $navy;
    }
    &.completed,
    &.service_requested_4 {
        color: #d3e100;
    }
    &.cancelled,
    &.service_requested_5 {
        color: #fc5b57;
    }
    &.refunded {
        color: $navy;
    }
}

.icon_service_requested {
    display: block;
    margin: 0 3px;
    font-size: 1.2rem;

    &.score1 {
        color: #ff8300;
    }
    &.score2 {
        color: $yellow;
    }
    &.score3 {
        color: #ffe500;
    }
    &.score4 {
        color: #d3e100;
    }
    &.score5 {
        color: #fc5b57;
    }
}

.circle-step {
    background: #ffffff;
    border: 1px solid #69737e;
    border-radius: 100%;
    display: block;
    height: 15px;
    margin-top: 6px;
    width: 15px;
}

.circle-step.done {
    border: 1px solid #1ea689;

    &::before {
        background: #1ea689;
        border: 1px solid #1ea689;
        border-radius: 10px;
        content: '';
        display: block;
        height: 9px;
        margin: 2px;
        width: 9px;
    }
}

.badge-training {
    background: $blue;
    color: $white;
}
.badge-exhibition {
    background: $light-green;
}
.badge-workshop {
    background: #fc5b57;
}


.flatpickr-clean {
    align-items: center;
    border: 1px solid #bbb;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    flex-flow: column;
    justify-content: center;
    padding: 0;
    position: relative;
}
.flatpickr-input[readonly] {
    background-color: $white;
    color: #343a40 !important;
    opacity: 1;
}

.services {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 150px;

    .service-bullet {
        margin-right: 10px;
        width: 75px;
    }

    .service-btn {
        border: 1px solid #bbb;
        border-radius: 50rem;
        color: #000;
        display: block;
        height: 60px;
        margin: 0 auto;
        padding: 16px;
        width: 60px;

        &:hover {
            background: #89bce8;
            i { color: #fff; }
        }

        &.active {
            background: $blue;
            i { color: #fff; }
        }
    }

    p {
        font-size: .8rem;
        font-weight: 500;
    }
}

.full-bkg-container {
    min-height: 100vh;
    background-size: cover !important;
    background-repeat: no-repeat;
}

.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}
.no-gutter > [class^='col-'] {
    padding-left: 0;
    padding-right: 0;
}

.bg-login {
    background-color: $nav-bg;
}
.login-panel {
    border-radius: 8px;
}
.paginate_button.active .page-link {
    background-color: $nav-bg !important;
}
.page-item.active .page-link {
    background-color: $nav-bg;
    border-color: $nav-bg;
}
.navbar-default .nav > li.active {
    border-left: 4px solid $nav-text-color-active;
}

// emphaze the selected sub item in the list
.navbar-default .nav ul > li.active {
    span { color: white; }
    a { background: #26333e; }
}

//custom style for nav
.navbar-default .nav {
    .nav-header {
        border: none;
    }
    li {
        // Prevent from side jumping effect from inactive to .active
        border-left: 4px solid transparent;
        // Base style for submenu list
        ul {
            list-style: none;
            li {
                position: relative;
                line-height: 30px;
                a {
                    color: $nav-text-color;
                }
                &.active {
                    border-left: none;
                    a {
                        color: $nav-text-color-active;
                    }
                }
                .border-fix {
                    width: 15px;
                    height: 100%;
                    // background: $nav-bg;
                    position: absolute;
                    top: 0px;
                    left: -8px;
                }
            }
        }
    }
    // Make all the icons the same width
    i {
        min-width: 15px;
    }
}

td {
    position: relative;
}
// strikeout the entire row of a table
tr.strikeout td:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    // border-bottom: 1px solid #111;
    width: 100%;
}
tr.strikeout {
    // background-color: #f3f3f4!important;
    background-color: #fce9e9!important;
    text-decoration:line-through;

}
tr.taken_into_account {
    background-color: #c7e6f9!important;
}
tr.to_take_into_account {
    background-color: #ffdfa1!important;
}

tr.strikeout.taken_into_account {
    background: rgb(199,230,249);
    background: linear-gradient(90deg, rgba(199,230,249,1) 0%, rgba(252,233,233,1) 65%);
}

#console-output {
    background: #073642;
    color: #6c6767;
    font-family: monospace;
    width: 100%;
    height: 450px;
    padding: 10px;
    border-radius: 4px;
    overflow: scroll;
    scrollbar-color: initial;
}

.popover-document-preview {
    list-style-type: none;
    padding-left: 1rem;

    i.fa-file-pdf {
        color: #c34242;
    }

    .btn-primary {
        background-color: #4a6b8b;
        font-size: .8rem;
        font-weight: 500;
    }

    p.filesize {
        font-size: .6rem;
        text-align: right;
    }
}
