.count-info .label {
    line-height: 10px;
    right: 3px;
}

.notification-bell > i {
    color: #4a6b8b;
    font-size: 1.1rem;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.notification-bell > i.notify {
    animation: ring 1.5s ease;
}

@keyframes ring {
    0% {
        transform: rotate(35deg);
    }
    12.5% {
        transform: rotate(-30deg);
    }
    25% {
        transform: rotate(25deg);
    }
    37.5% {
        transform: rotate(-20deg);
    }
    50% {
        transform: rotate(15deg);
    }
    62.5% {
        transform: rotate(-10deg);
    }
    75% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
