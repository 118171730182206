.fade-out {
  animation: fadeOut ease 2.5s forwards;
  -webkit-animation: fadeOut ease 2.5s forwards;
  -moz-animation: fadeOut ease 2.5s forwards;
  -o-animation: fadeOut ease 2.5s forwards;
  -ms-animation: fadeOut ease 2.5s forwards;
  transform: scale(1);
}
@keyframes fadeOut {
  0% {
    opacity:1;
    transform: scale(1);
  }
  7% {
    opacity:1;
    transform: scale(1.15);
  }
  15% {
    opacity:1;
    transform: scale(1);
  }
  99% {
    opacity:0;
    transform: scale(1);
  }
  100% {
    opacity:0;
    transform: scale(1);
    display: none!important;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
    transform: scale(1);
  }
  7% {
    opacity:1;
    transform: scale(1.15);
  }
  15% {
    opacity:1;
    transform: scale(1);
  }
  99% {
    opacity:0;
    transform: scale(1);
  }
  100% {
    opacity:0;
    transform: scale(1);
    display: none!important;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
    transform: scale(1);
  }
  7% {
    opacity:1;
    transform: scale(1.15);
  }
  15% {
    opacity:1;
    transform: scale(1);
  }
  99% {
    opacity:0;
    transform: scale(1);
  }
  100% {
    opacity:0;
    transform: scale(1);
    display: none!important;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
    transform: scale(1);
  }
  7% {
    opacity:1;
    transform: scale(1.15);
  }
  15% {
    opacity:1;
    transform: scale(1);
  }
  99% {
    opacity:0;
    transform: scale(1);
  }
  100% {
    opacity:0;
    transform: scale(1);
    display: none!important;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
    transform: scale(1);
  }
  7% {
    opacity:1;
    transform: scale(1.15);
  }
  15% {
    opacity:1;
    transform: scale(1);
  }
  99% {
    opacity:0;
    transform: scale(1);
  }
  100% {
    opacity:0;
    transform: scale(1);
    display: none!important;
}
}