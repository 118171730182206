.choices {
    margin-bottom: initial;

    .choices__inner {
        background-color: initial;
        border-color: #e5e6e7;
        border-radius: 0;
        min-height: 35px;
        padding: 3px 7.5px 2.75px;
    }

    .choices__input {
        background-color: initial;
    }

    .choices__list--dropdown {
        border: 1px solid #e5e6e7;

        .choices__item {
            padding: 7px;
        }

        .choices__item--selectable {
            padding-right: initial;
        }
    }

    .choices__list--multiple {
        .choices__item {
            border-radius: 4px;
            background-color: #007bff;
            border: none;
            margin-bottom: 3px;
        }
    }
}

.choices[data-type*='select-one'] {
    .choices__inner {
        padding-bottom: initial;
    }

    .choices__input {
        background-color: #fffbdd;
        padding: 7px;
    }
}
