// Main Buttons
.btn-cta-label {
    border-top-right-radius: $cta-border-radius;
    border-bottom-right-radius: $cta-border-radius;
    padding: 0px 0.5rem;
    //min-width: $cta-min-width;
    box-sizing: border-box;
    font-weight: bold;
}
.btn-cta-icon {
    border-top-left-radius: $cta-border-radius;
    border-bottom-left-radius: $cta-border-radius;
    padding: 0.6rem;
    min-width: $cta-height;
}
.btn-cta-icon-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $cta-border-radius;
    border-bottom-right-radius: $cta-border-radius;
}
.btn-cta {
    min-height: $cta-height;
    line-height: $cta-height;
    padding: 0px;
    vertical-align: middle;
    -webkit-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
    transform: scale(1);
    transition: all 0.3s;
    border: none;
    &:hover {
        //transform: scale(1.01);
    }
    &.w-auto {
        .btn-cta-label {
            min-width: none !important;
        }
    }
    &.in-dark-context {
        .btn-cta-icon {
            background: white;
            color: #222654;
        }
        .btn-cta-label {
            background: white;
            color: black;
        }
    }
    &.no-shadow {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    &.in-light-context {
        .btn-cta-icon {
            background: $cta-color;
            color: white;
        }
        .btn-cta-label {
            background: #f8f8f8;
            color: black;
        }
    }
    &.secondary {
        .btn-cta-icon,
        .btn-cta.icon-right {
            background: $cta-secondary-color;
            color: $cta-icon-color;
        }
        .btn-cta-label {
            font-weight: normal !important;
            background: white;
            color: #222;

            small.badge {
                margin-left: 2px;
                top: initial;
            }
        }
        &:hover {
            .btn-cta-label {
                text-shadow: -0.06ex 0 black, 0.06ex 0 black;
            }
        }
    }
    &.warning {
        .btn-cta-icon,
        .btn-cta.icon-right {
            background: $cta-warning-color;
            color: white;
        }
    }
    &.success {
        .btn-cta-icon,
        .btn-cta.icon-right {
            background: $cta-success-color;
            color: white;
        }
    }
    &.negative {
        .btn-cta-icon,
        .btn-cta.icon-right {
            background: $red;
            color: white;
        }
    }
    &.positive {
        .btn-cta-icon,
        .btn-cta.icon-right {
            background: $cta-success-color;
            color: white;
        }
    }
    &.btn-login {
        width: 100%;
        background: white;
        .btn-cta-icon {
            width: $cta-height;
            background: transparent;
        }
        .btn-cta-label {
            width: calc(100% - #{$cta-height});
        }
    }
    &.btn-w-100 {
        width: 100%;
        .btn-cta-label {
            width: calc(100% - #{$cta-height});
        }
    }
    &.button-add {
        .btn-cta-icon {
            padding: 0px;
        }
    }
}
// Tab Pills
.app-tab-pills-tab {
    &.active {
        background: white !important;
        color: $cta-color !important;
        font-weight: bold;
        -webkit-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
        .btn-cta-icon {
            background: $cta-color;
            color: white;
        }
        .btn-cta-label {
            color: black;
            font-weight: bold;
        }
    }
    .btn-cta-icon {
        background: white;
        color: $cta-secondary-color;
    }
    .btn-cta-label {
        color: $cta-color;
        font-weight: normal;
    }
    padding-left: 0px;
    transform: scale(1);
    transition: all 0.15s;
    border: none;
    // &:hover {
    //     transform: scale(1.01);
    // }
    &.disabled {
        pointer-events: auto !important;
        .btn-cta-icon {
            color: $text-disabled;
        }
        .btn-cta-label {
            color: $text-disabled;
        }
    }
}
input[type='checkbox'] {
    &:checked {
        background: $cta-color;
    }
}
.btn-cta-save {
    background: $cta-color;
    transform: scale(1);
    transition: all 0.25s;
    color: white;
    -webkit-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    &:hover {
        transform: scale(1.1);
        color: white;
    }
}
.choices .choices__list--multiple .choices__item {
    background-color: $cta-secondary-color;
}
.logo-element {
    background-color: $nav-grey;
}
.modal-header {
    background: #eee !important;
}
.nb {
    border: none !important;
}
.badge {
    &.badge-processing {
        background: orange;
        color: white;
    }
    &.badge-completed {
        background: $cta-success-color;
        color: white;
    }
    &.badge-cancelled {
        background: red;
        color: white;
    }
}

.btn-outline-secondary {
    //color: #6c757d;
    border-color: #e5e6e7;
    i {
        color: $cta-secondary-color;
    }
    &:hover {
        border-color: $cta-secondary-color;
        background: $cta-secondary-color;
        i {
            color: white;
        }
    }
}
th[data-select-all=''] {
    cursor: pointer;
}
table.dataTable tbody tr.selected {
    background-color: $selected-row-bg;
}
.datatable-action-zone {
    // position: relative;
    // background: white;
    // padding: 20px 0px 0px 0px;
    // overflow:hidden;
    transition: all 0.3s;
    .selection-prompt {
        opacity: 0;
        position: absolute;
        top: 0px;
        left: 5px;
    }
    button,
    .selection-message,
    .selection-prompt {
        transition: opacity 0.2s;
        opacity: 0;
        cursor: unset !important;
    }
    &.active {
        button,
        .selection-message,
        .selection-prompt {
            opacity: 1;
            cursor: pointer !important;
        }
        button:disabled {
            opacity: 0.5;
        }
    }
    [data-expand-selection-to-hidden] {
        display: none;
        &.active {
            display: inline-block;
        }
    }
    [data-select-count] {
        font-weight: bold;
    }
}

[data-form-toggle-checkbox] {
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
}

.dataTables_wrapper {
    .dropdown-menu {
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 25px;
    }
}

.form-filters {
    .input-button {
        &.datepicker-clear {
            background: $cta-secondary-color;
            padding: 8px;
            border-top-right-radius: $cta-border-radius;
            border-bottom-right-radius: $cta-border-radius;
        }
        i {
            color: $cta-icon-color;
        }
    }
    select.form-control {
        height: calc(1.5em + 0.75rem + 2px)!important;
    }

    .btn-cta {
        box-shadow: none;
        border: 1px solid #e5e6e7;
        min-height: 35px;
        line-height: 35px;
    }
}

.panel-notification-emails {
    background: #f7f7f7;
    border: 1px solid #e9e9e9;
    color: #6f7274;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}
